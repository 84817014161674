import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { ReportsService } from '../services/reports.service';
import { UtilsService } from '../services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from '../dialog/confirm-delete/confirm-delete.component';



@Component({
  selector: 'app-redis-keys',
  templateUrl: './redis-keys.component.html',
  styleUrls: ['./redis-keys.component.css']
})
export class RedisKeysComponent implements OnInit {

  showFiller = false;
  displayedColumns: string[] = ['type', 'key', 'ttl'];
  dataSource = new MatTableDataSource<RedisScan>([]);
  drawerValue: null;
  selectedRow: RedisScan;

  cursor: number = 0;
  pattern: string = '*';
  rows: number = 20;
  retrieving: boolean = false;

  @ViewChild('drawer') drawer: MatDrawer;
  constructor(private dialog: MatDialog, private reportService: ReportsService, public utils: UtilsService) { }

  ngOnInit(): void {
    this.addSkeleton();
    this.retrieveKeys(this.cursor, this.pattern, this.rows)
  }



  retrieveKeys(cursor: number, match: string, count: number, search_mode: boolean = false) {
    if (this.retrieving) {
      return false;
    }
    this.retrieving = true;
    return this.reportService.getRedisKeys(cursor, match, count)
      .subscribe((result: RedisScanResult) => {
        if (search_mode) {
          this.dataSource.data = result.results;
        } else {
          this.dataSource.data = [...this.dataSource.data, ...result.results];
        }
        this.cursor = result.next_cursor;
        this.removeSkeleton();
      }, error => {
        console.log("Scan Error:\n", error);
        this.retrieving = false;
      })

  }

  addSkeleton() {
    let skeleton: RedisScan[] = []
    for (let index = 0; index <= 5; index++) {
      const dummy: RedisScan = { "key": "skeleton", "ttl": index, "type": "" }
      skeleton.push(dummy);
    }
    this.dataSource.data = [...this.dataSource.data, ...skeleton];
  }

  removeSkeleton() {
    let rows = this.dataSource.data.filter((item: RedisScan) => item.key !== 'skeleton');
    this.dataSource.data = rows;
    this.retrieving = false;
  }

  onEnter(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    this.pattern = input.value.trim() === "" ? "*" : input.value;
    this.dataSource.data = [];
    this.addSkeleton();
    this.retrieveKeys(0, this.pattern, 200, true);
  }


  readKeyValue(row: RedisScan) {
    if (this.drawer) {
      this.selectedRow = row
      this.drawer.open()
      this.reportService.getKeyValue(row.key, row.type)
        .subscribe((value: any) => {
          this.drawerValue = value
        }, error => {
          console.error(`Error retrieving value for key ${row.key} \n`, error);
        })
    }
  }

  getBadgeClass(type: string): string {
    switch (type) {
      case 'string':
        return 'badge badge-string';
      case 'hash':
        return 'badge badge-hash';
      case 'list':
        return 'badge badge-list';
      case 'json':
        return 'badge badge-json';
      default:
        return 'badge badge-default';
    }
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    // Check if the user scrolled to the bottom

    const isBottom = (target.scrollHeight - target.scrollTop) - target.clientHeight <= 2;

    console.log(isBottom);


    if (isBottom && this.cursor !== 0) {
      this.addSkeleton();
      this.retrieveKeys(this.cursor, this.pattern, this.rows)

    }
  }

  deletekey(title: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        title: title,
        content: 'will be deleted. Proceed?'
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reportService.deleteRedisCache(this.selectedRow.key)
          .subscribe((result: SuccessResponse) => {
            const msg = result.success ? this.selectedRow.key + " has been deleted." : "An error occured while attempting to delete the key";
            alert(msg)
            let newTableItems = this.dataSource.data.filter((item: RedisScan) => item.key !== this.selectedRow.key);
            this.dataSource.data = newTableItems;
            this.drawer.close();
          }, error => console.error("Key delete error: \n", error));
      }
    });
  }


}
