import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-wrong-answer-round',
  templateUrl: './wrong-answer-round.component.html',
  styleUrls: ['./wrong-answer-round.component.css']
})
export class WrongAnswerRoundComponent implements OnInit {

	@Input() round: WrongAnswerRound

	showQuestions = false;

	constructor() { }

	ngOnInit(): void {
	}

}
