import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ReportsService {

	rootUri = (environment.ktRootUri) ? environment.ktRootUri : "";

	constructor(
		private http: HttpClient
	) { }

	getWrongAnswerReport(set_id: number) {
		return this.http.get(`${this.rootUri}/api/reports/wrong-answers/${set_id}`);
	}

	getNumberOfLiveGames(): Observable<number> {
		return this.http.get<number>(`${this.rootUri}/api/reports/total-live-games`);
	}

	getNumberOfLiveShifts(): Observable<number> {
		return this.http.get<number>(`${this.rootUri}/api/reports/total-live-shifts`);
	}
	getNumberOfTeams(): Observable<number> {
		return this.http.get<number>(`${this.rootUri}/api/reports/total-teams`);
	}
	getNumberOfPlayers(): Observable<number> {
		return this.http.get<number>(`${this.rootUri}/api/reports/total-players`);
	}
	getScheduledTasks(): Observable<ScheduledTasks[]> {
		return this.http.get<ScheduledTasks[]>(`${this.rootUri}/api/reports/scheduled-tasks`);
	}

	getServerStats(): Observable<ServerStats> {
		return this.http.get<ServerStats>(`${this.rootUri}/api/reports/server-stats`);
	}

	getHistoricalDashboardStats(): Observable<StatsArchive[]> {
		return this.http.get<StatsArchive[]>(`${this.rootUri}/api/reports/historical-records`)
	}

	getPlayersByVenue(venue: string, date_from: string, date_to: string): Observable<PlayersByVenue> {
		return this.http.post<PlayersByVenue>(`${this.rootUri}/api/reports/players-by-venue`, { venue, date_from, date_to })
	}

  getRedisKeys(cursor: number = 0, match: string = '*', count: number = 10): Observable<RedisScanResult> {
    return this.http.get<RedisScanResult>(`${this.rootUri}/api/reports/redis-keys`,
      {
        params:
          { 'cursor': cursor.toString(), 'match': match.toString(), 'count': count.toString() }
      });
  }

  getKeyValue(key: string, type: string): Observable<RedisScanResult> {
    return this.http.get<any>(`${this.rootUri}/api/reports/redis-keys/read/${key}/${type}`);
  }

  deleteRedisCache(key: string): Observable<SuccessResponse> {
    return this.http.delete<SuccessResponse>(`${this.rootUri}/api/reports/redis-keys/delete/${key}`);
  }
}
