import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { RecapService } from '../services/recap.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-recap-timecard',
  templateUrl: './recap-timecard.component.html',
  styleUrls: ['./recap-timecard.component.scss']
})
export class RecapTimecardComponent implements OnInit {
  @Input() shiftData: RecapShiftData;
  @Input() gameData: RecapGameData;
  @Input() user: User;
  @Input() tz: string;

  @Output() hideTimecardEvent = new EventEmitter<boolean>();

  shiftHostData: ShiftHost[];
  workBlockData: WorkBlock[];
  notesData: OfficeNote[];

  addNotesBool: boolean;
  addNotesLoading: boolean;
  tcApprovedBool: boolean;
  bonusBool: boolean;
  showDidNotHappen: boolean;
  startTimeOver: boolean;
  endTimeOver: boolean;
  approvalLoading: boolean = false;
  showHappLoading: boolean = false;
  bonusLoading: boolean = false;
  editPayBool: boolean = false;
  isRevDropdownOpen: boolean = false;
  isRevModalOpen: boolean = false;
  needsRevision: boolean;
  notesLoading: boolean = false;
  revisionLoading: boolean = false;
  showTooltip: boolean = false;
  targetPayLoading: boolean = false;

  emptyTablesCount: number;
  hoursArray: number[] = [];
  hourlyPay: number;
  newTargetPay: number;
  playerCount: number;
  questionRating: number;
  targetPay: number;
  teamCount: number;
  totalBonusPay: number;
  totalHourlyPay: number;
  totalHours: number;
  totalPay: number;

  blogFeedback: string;
  shiftStartTime: string;
  shiftEndTime: string;
  expStartTime: string;
  expEndTime: string;
  officeNote: string = '';

  constructor(private recapServ: RecapService) { }

  ngOnInit(): void {
    this.shiftHostData = this.shiftData.user;
    this.workBlockData = this.shiftData.work;
    this.blogFeedback = this.shiftData.blog_feedback;
    this.notesData = this.shiftData.office_notes;
    this.bonusBool = this.shiftData.bonus;
    this.emptyTablesCount = this.shiftData.empty_tables;
    this.hourlyPay = this.shiftData.hourly_rate;
    this.playerCount = this.shiftData.total_players;
    this.questionRating = this.shiftData.question_rating;
    this.targetPay = this.shiftData.target_rate;
    this.teamCount = this.shiftData.qm_teams;
    this.showDidNotHappen = this.shiftData.no_show;

    this.shiftData.status === 'review' ? this.needsRevision = true : this.needsRevision = false;

    this.totalHours = 0;
    for (let i = 0; i < this.shiftData.work.length; i++) {
    	this.setWorkBlockFormattedTime(this.shiftData.work[i], (!i));
    	this.totalHours = Math.round((this.shiftData.work[i].total_hours + this.totalHours) * 100) / 100;
    }

    this.calculatePay()
  }

  calculatePay() {
  	this.totalHourlyPay = Math.round(this.totalHours * this.hourlyPay * 100) / 100;
    this.totalBonusPay = Math.round((this.targetPay - this.totalHourlyPay) * 100) / 100;
    if (this.totalBonusPay < 0 || !this.bonusBool) this.totalBonusPay = 0;
    this.totalPay = Math.round((this.totalHourlyPay + this.totalBonusPay) * 100) / 100;
  }

  setWorkBlockFormattedTime(block: WorkBlock, checkExpected: boolean = false) {
  	if (block.start_work === null || block.end_work == null) block.timeMissing = true;
  	else {
      const startDateObject = new Date(block.start_work);
      const endDateObject = new Date(block.end_work);
      block.start_time_formatted = this.recapServ.formatTime(this.gameData.venue_tz, block.start_work);
      block.end_time_formatted = this.recapServ.formatTime(this.gameData.venue_tz, block.end_work);

      const startCompare: any = startDateObject;
      const endCompare: any = endDateObject;
      const timeDifference = endCompare - startCompare;
	    block.total_hours = timeDifference >= 0 ? Math.round(timeDifference / (1000 * 60 * 60) * 100) / 100 : 0;

	    if (checkExpected) {
        const exStart:any = new Date(this.shiftData.expected_start);
        const exEnd:any = new Date(this.shiftData.expected_end);
        this.shiftStartTime = this.recapServ.formatTime(this.gameData.venue_tz, block.start_work);
        this.shiftEndTime = this.recapServ.formatTime(this.gameData.venue_tz, block.end_work);
        this.expStartTime = this.recapServ.formatTime(this.gameData.venue_tz, exStart);
        this.expEndTime = this.recapServ.formatTime(this.gameData.venue_tz, exEnd);

		    this.startTimeOver = this.recapServ.isTimeDifferenceOverThreshold(exStart, startDateObject);
        this.endTimeOver = this.recapServ.isTimeDifferenceOverThreshold(exEnd, endDateObject);
	    }
  	}
  }

  toggleShowHappen() {
    this.showHappLoading = true;
    this.showDidNotHappen = !this.showDidNotHappen;

    this.recapServ.toggleShowDidNotHappen(this.shiftData.shiftid, this.showDidNotHappen)
      .pipe(finalize(() => this.showHappLoading = false))
      .subscribe((res: RecapShiftData) => {
        this.showDidNotHappen = this.shiftData.no_show = res.no_show;
        console.log(res.no_show);
      },
      err => console.log(err.error.detail))
  }

  changeTargetPay() {
    this.targetPayLoading = true;
    this.editPayBool = false;
    this.recapServ.changeTargetRate(this.shiftData.shiftid, this.targetPay)
    	.pipe(finalize(() => this.targetPayLoading = false))
    	.subscribe((res: RecapShiftData) => {
    		this.shiftData.target_rate = res.target_rate;
    		this.targetPay = res.target_rate;
        	this.calculatePay();
    	},
    	err => console.log(err.error.detail))
  }

  toggleBonus() {
    this.bonusLoading = true;
    this.bonusBool = !this.bonusBool;
    this.recapServ.toggleTimecardBonus(this.shiftData.shiftid, this.bonusBool)
    	.pipe(finalize(() => this.bonusLoading = false))
    	.subscribe((res: RecapShiftData) => {
    		this.bonusBool = res.bonus;
    		this.shiftData.bonus = res.bonus;
        	this.calculatePay();
    	},
    	err => console.log(err.error.detail))
  }

  toggleDropdown() {
    this.isRevDropdownOpen = !this.isRevDropdownOpen;
  }

  activateAddNotes() {
    this.addNotesBool = !this.addNotesBool;
  }

  cancelOfficeNote() {
    this.addNotesBool = !this.addNotesBool;
  }

  addOfficeNote() {
    this.addNotesLoading = true;
    this.recapServ.addOfficeNote(this.shiftData.shiftid, this.officeNote)
    	.pipe(finalize(() => this.addNotesBool = this.addNotesLoading = false))
      .subscribe((res: RecapShiftData) => {

        this.officeNote = "";
        this.shiftData.office_notes = res.office_notes;
    	},
    	err => console.log(err.error.detail))
  }

}
