import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { HeaderComponent } from './header/header.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SurveysComponent } from './surveys/surveys.component';
import { SurveyComponent } from './survey/survey.component';
import { SearchByPropPipe } from './search-by-prop.pipe';
import { ResizingTextareaComponent } from './resizing-textarea/resizing-textarea.component';
import { RecapsComponent } from './recaps/recaps.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { TypeaheadPipe } from './typeahead.pipe';
import { RecapComponent } from './recap/recap.component';
import { RecapTimecardComponent } from './recap-timecard/recap-timecard.component';
import { RecapOfficeNotesComponent } from './recap-office-notes/recap-office-notes.component';
import { RecapPublicComponent } from './recap-public/recap-public.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { ImageListComponent } from './image-list/image-list.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BasicModalComponent } from './basic-modal/basic-modal.component';
import { SvgEditComponent } from './icons/svg-edit/svg-edit.component';
import { SvgTrashComponent } from './icons/svg-trash/svg-trash.component';
import { SvgDownArrowComponent } from './icons/svg-down-arrow/svg-down-arrow.component';
import { SvgUpArrowComponent } from './icons/svg-up-arrow/svg-up-arrow.component';
import { SvgSaveComponent } from './icons/svg-save/svg-save.component';
import { SvgCancelComponent } from './icons/svg-cancel/svg-cancel.component';
import { SvgAddComponent } from './icons/svg-add/svg-add.component';
import { SvgLoadingLightComponent } from './icons/svg-loading-light/svg-loading-light.component';
import { SvgLoadingDarkComponent } from './icons/svg-loading-dark/svg-loading-dark.component';
import { SvgCautionComponent } from './icons/svg-caution/svg-caution.component';
import { BasicTooltipComponent } from './basic-tooltip/basic-tooltip.component';
import { SingleRecapViewerComponent } from './single-recap-viewer/single-recap-viewer.component';
import { GameListComponent } from './game-list/game-list.component';
import { GameStubComponent } from './game-stub/game-stub.component';
import { GameSearchPipe } from './game-search.pipe';
import { SvgThumbsUpComponent } from './icons/svg-thumbs-up/svg-thumbs-up.component';
import { SvgThumbsDownComponent } from './icons/svg-thumbs-down/svg-thumbs-down.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationComponent } from './notification/notification.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { ReportsComponent } from './reports/reports.component';
import { WrongAnswerComponent } from './wrong-answer/wrong-answer.component';
import { WrongAnswerQuestionComponent } from './wrong-answer-question/wrong-answer-question.component';
import { WrongAnswerRoundComponent } from './wrong-answer-round/wrong-answer-round.component';
import { SurveyEditComponent } from './survey-edit/survey-edit.component';
import { SurveyNewComponent } from './survey-new/survey-new.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { SurveyOptionsComponent } from './survey-options/survey-options.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CircularProgressBarComponent } from './circular-progress-bar/circular-progress-bar.component';
import { StatsCardComponent } from './stats-card/stats-card.component';
import { HistoricalDataComponent } from './historical-data/historical-data.component';
import { ChartModule, HIGHCHARTS_MODULES, MapChart } from 'angular-highcharts';
import { PlayersByVenueReportComponent } from './players-by-venue-report/players-by-venue-report.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { RedisKeysComponent } from './redis-keys/redis-keys.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfirmDeleteComponent } from './dialog/confirm-delete/confirm-delete.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HeaderComponent,
    MainMenuComponent,
    SurveysComponent,
    SurveyComponent,
    SearchByPropPipe,
    ResizingTextareaComponent,
    RecapsComponent,
    TypeaheadComponent,
    TypeaheadPipe,
    RecapComponent,
    RecapTimecardComponent,
    RecapOfficeNotesComponent,
    RecapPublicComponent,
    ImageCarouselComponent,
    ImageListComponent,
    SafeHtmlPipe,
    BasicModalComponent,
    SvgEditComponent,
    SvgTrashComponent,
    SvgDownArrowComponent,
    SvgUpArrowComponent,
    SvgSaveComponent,
    SvgCancelComponent,
    SvgAddComponent,
    SvgLoadingLightComponent,
    SvgLoadingDarkComponent,
    SvgCautionComponent,
    BasicTooltipComponent,
    SingleRecapViewerComponent,
    GameListComponent,
    GameStubComponent,
    GameSearchPipe,
    SvgThumbsUpComponent,
    SvgThumbsDownComponent,
    NotificationsComponent,
    NotificationComponent,
    SkeletonLoaderComponent,
    ReportsComponent,
    WrongAnswerComponent,
    WrongAnswerQuestionComponent,
    WrongAnswerRoundComponent,
    SurveyEditComponent,
    SurveyNewComponent,
    CheckboxInputComponent,
    SurveyOptionsComponent,
    DashboardComponent,
    CircularProgressBarComponent,
    StatsCardComponent,
    HistoricalDataComponent,
    PlayersByVenueReportComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    RedisKeysComponent,
    ConfirmDeleteComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    EditorModule,
    ChartModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    NgxJsonViewerModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
