import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SurveyService } from '../services/survey.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-survey-edit',
  templateUrl: './survey-edit.component.html',
  styleUrls: ['./survey-edit.component.css']
})
export class SurveyEditComponent implements OnInit {

	@Input() survey: Survey;

	addOptions: boolean;

	surveyStates = [
		{ label: "Inactive", class: "", route: "deactivate", prev: "", next: "Start Survey" },
		{ label: "Active", class: "orange", route: "start", prev:" Deactivate", next: "End Survey" },
		{ label: "Completed", class: "green", route: "end", prev: "Reopen Survey", next: "Finalize Results" },
		{ label: "Final", class: "", route: "finalize", prev: "Un-finalize", next: "Mark Used" },
		{ label: "Used", class: "", route: "mark-used", prev: "Mark Unused", next: "" }
	]

	loading: boolean;
	err = "";
	editing = ""
	choosingGroup: boolean;
	chosenGroup = ""
	responses: GroupedResponse[] = []
	options: string[] = []

	constructor(private surveyServ: SurveyService) { }

	ngOnInit(): void {
		this.responses = this.groupResponses()
		this.options = [...this.survey.options];
	}

	status(type: string = "label") {
		let stateIndex = 0;
		if (this.survey.used) stateIndex = 4;
		else if (this.survey.finalized) stateIndex = 3;
		else if (this.survey.completed) stateIndex = 2;
		else if (this.survey.active) stateIndex = 1;
		return type === 'index' ? stateIndex : this.surveyStates[stateIndex][type]
	}

	prevStatus(type: string = 'label') {
		let sIndex = this.status('index');
		if (sIndex < 1) return;
		let prev = this.surveyStates[sIndex - 1];
		return prev[type];
	}

	nextStatus(type: string = 'label') {
		let sIndex = this.status('index');
		if (sIndex >= this.surveyStates.length - 1) return;
		let next = this.surveyStates[sIndex + 1];
		return next[type];
	}

	setPrevState() {
		this.setState(this.prevStatus('route'))
	}

	setNextState() {
		this.setState(this.nextStatus('route'))
	}

	setState(state: string) {
		if (!state) return;
		this.loading = true;
		this.err = "";
		this.surveyServ.setStatus(this.survey.surveyid, state)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: SurveySuccess) => {
					this.updateFrom(res.survey);
					this.choosingGroup = false;
				},
				err => this.err = err.error.detail)
	}

	groupResponses() {
		let grouped: GroupedResponse[] = [];
		for (let g of this.survey.groups) {
			grouped.push({
				group_text: g.group_text,
				group_code: g.group_code,
				responses: g.responses,
				num: 0
			})
		}
		for (let r of this.survey.responses) {
			let inGroup = false;
			for (let g of grouped) {
				if (g.responses.includes(r.response_text.toLowerCase())) {
					inGroup = true;
					g.num++;
					break;
				}
			}
			if (!inGroup) {
				grouped.push({
					group_text: r.response_text,
					group_code: "",
					responses: [r.response_text.toLowerCase()],
					num: 1
				})
			}
		}
		let out: GroupedResponse[] = []
		for (let g of grouped) {
			if (g.num) {
				g.percent = Math.round(g.num / this.survey.responses.length * 100);
				out.push(g)
			}
		}
		return out.sort((a, b) => {
			if (a.percent > b.percent) return -1;
			if (a.percent < b.percent) return 1;
			else return 0;
		})
	}

	topResponse() {
		if (!this.responses.length) return "";
		let topPercent = this.responses[0].percent;
		let top = `${this.responses[0].group_text} (${topPercent}%)`;
		for (let i = 1; i < this.responses.length; i++) {
			if (this.responses[i].percent === topPercent) {
				top = `${top} and ${this.responses[i].group_text} (${this.responses[i].percent}%)`;
			}
		}
		return top;
	}

	edit(update: string) {
		if (!this.editing) return;
		if (update !== this.survey[this.editing]) {
			let updater: any = {};
			updater[this.editing] = update;
			this.commitUpdate(updater)
		}
		else this.editing = "";
	}

	updateOptions() {
		if (!this.editing) return;
		let updater: any = {options: this.options};
		this.commitUpdate(updater)
	}

	commitUpdate(updater: any) {
		this.loading = true;
		this.surveyServ.updateSurvey(this.survey.surveyid, updater)
			.pipe(finalize(() => {
				this.editing = "";
				this.loading = false;
			}))
			.subscribe(
				(res: SurveySuccess) => this.updateFrom(res.survey),
				err => this.err = err.error.detail)
	}

	startGrouping() {
		this.chosenGroup = "";
		this.choosingGroup = true;
	}

	addToGroup(group_code: string) {
		if (!group_code) return;
		this.loading = true;
		this.err = ""
		this.surveyServ.addToGroup(this.survey.surveyid, group_code, this.getSelected())
			.pipe(finalize(() => {
				this.choosingGroup = false;
				this.loading = false;
			}))
			.subscribe(
				(res: SurveySuccess) => {
					this.updateFrom(res.survey);
					this.choosingGroup = false;
				},
				err => this.err = err.error.detail)
	}

	newGroup(group_text: string) {
		if (!group_text) return;
		this.loading = true;
		this.err = "";
		this.surveyServ.newGroup(this.survey.surveyid, group_text, this.getSelected())
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: SurveySuccess) => {
					this.updateFrom(res.survey);
					this.choosingGroup = false;
				},
				err => this.err = err.error.detail)
	}

	removeGroup(group_code) {
		if (!group_code) return;
		this.loading = true;
		this.err = "";
		this.surveyServ.removeGroup(this.survey.surveyid, group_code)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: SurveySuccess) => this.updateFrom(res.survey),
				err => this.err = err.error.detail)
	}

	ungroupResponse(response: string) {
		if (!response) return;
		this.loading = true;
		this.err = "";
		this.surveyServ.ungroupResponses(this.survey.surveyid, [response])
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: SurveySuccess) => {
					this.updateFrom(res.survey);
					this.choosingGroup = false;
				},
				err => this.err = err.error.detail)
	}

	updateFrom(survey: Survey) {
		for (let prop in survey) {
			this.survey[prop] = survey[prop]
		}
    this.responses = this.groupResponses()
	}

	getSelected() {
		let out: string[] = [];
		for (let r of this.responses) {
			if (r.checked) out.push(r.group_text);
		}
		return out;
	}

	getGroups() {
		return this.responses.filter((r) => {
			if (r.group_code) return r;
		})
	}

	clearIfNoneChecked() {
		console.log(this.getSelected())
		if (!this.getSelected().length) this.choosingGroup = false;
	}
}
