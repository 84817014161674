import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  validateDateRange(startDate, endDate) {

    if (!startDate || !endDate) {
      return "Start and End date are required.";
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDifference = end.getTime() - start.getTime();

    // Convert time difference to days
    const dayDifference = timeDifference / (1000 * 3600 * 24);

    // Check if the difference is greater than 90 days
    if (dayDifference > 90) {
      return "Please select a date range of 90 days or less.";
    }

    return "valid";
  }

  convertSecondsToReadableFormat(seconds: number): string {
    if (seconds < 60) {
      // Less than 60 seconds

      return seconds == -1 ? "No limit" : `${seconds}s`;
    } else if (seconds < 3600) {
      // Less than 1 hour
      const minutes = seconds / 60;
      return minutes >= 10
        ? `${Math.round(minutes)}m`
        : `${minutes.toFixed(1)}m`;
    } else {
      // 1 hour or more
      const hours = seconds / 3600;
      return hours >= 10
        ? `${Math.round(hours)}h`
        : `${hours.toFixed()}h`;
    }
  }


}
