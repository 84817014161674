<div class="redis-page-container">
    <!-- HEADING -->
    <header id="page-header">
      <h3 class="no-m sm-px pt-10 std-pt">Dragonfly DB</h3>
    <div class="md-p">
      <mat-form-field class="w-100">
        <mat-label>Filter by Key Name or Pattern</mat-label>
        <input type="search" matNativeControl matInput (keydown.enter)="onEnter($event)">
      </mat-form-field>

    </div>
    </header>
    <mat-drawer-container id="mat-drawer">


    <!-- TABLE CONTAINER -->
    <div class="table-container card" *ngIf="dataSource" (scroll)="onScroll($event)">
      <table mat-table [dataSource]="dataSource" class="w-100">

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type </th>
          <td mat-cell *matCellDef="let element">
            <span [ngClass]="getBadgeClass(element.type)" [class.skeleton]="element.key === 'skeleton'"
              [class.skeleton]="element.key === 'skeleton'">
              {{ element.type }}
            </span>
          </td>
        </ng-container>

        <!-- Key Column -->
        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef> Key </th>
          <td mat-cell *matCellDef="let element">
            <p [class.skeleton]="element.key === 'skeleton'" [class.tbl-col-75]="element.key === 'skeleton'">{{element.key ===
              'skeleton'? '':element.key}}</p>
          </td>
        </ng-container>

        <!-- TTL Column -->
        <ng-container matColumnDef="ttl">
          <th mat-header-cell *matHeaderCellDef> TTL </th>
          <td mat-cell *matCellDef="let element">
            <p [class.skeleton]="element.key === 'skeleton'">
              {{ element.key === 'skeleton' ?'':
              utils.convertSecondsToReadableFormat(element.ttl) }}
            </p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="readKeyValue(row)"
          [class.selected-row]="selectedRow === row"></tr>
      </table>
    </div>

    <!-- DRAWER -->
    <mat-drawer #drawer id="redis-key-drawer" mode="side" position="end">
      <mat-toolbar *ngIf="selectedRow" class="drawer-toolbar">
        <span [ngClass]="getBadgeClass(selectedRow.type)">
          {{ selectedRow.type }}
        </span>
        <p id="drawer-key">{{ selectedRow.key }}</p>
        <span class="example-spacer"></span>
        <button mat-icon-button color="error" (click)="deletekey(selectedRow.key)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-button class="sm-ml" (click)="drawer.close(); selectedRow = null">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar>

      <div class="drawer-content">
        <ngx-json-viewer [json]="drawerValue" [expanded]="false"></ngx-json-viewer>
      </div>
    </mat-drawer>

  </mat-drawer-container>
</div>
