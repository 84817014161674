import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveysComponent } from './surveys/surveys.component';
import { RecapsComponent } from './recaps/recaps.component';
import { SingleRecapViewerComponent } from './single-recap-viewer/single-recap-viewer.component';
import { AuthGuard } from './auth/auth.guard';
import { GameListComponent } from './game-list/game-list.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ReportsComponent } from './reports/reports.component';
import { WrongAnswerComponent } from './wrong-answer/wrong-answer.component';
// import { HistoricalDataComponent } from './historical-data/historical-data.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthComponent } from './auth/auth.component';
import { PlayersByVenueReportComponent } from './players-by-venue-report/players-by-venue-report.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { RedisKeysComponent } from './redis-keys/redis-keys.component';

const routes: Routes = [
	{ path: "auth", component: AuthComponent },
	{ path: "forgot-password", component: ForgotPasswordComponent },
	{ path: "new-password/:token", component: NewPasswordComponent },
	{ path: "", component: DashboardComponent, canActivate: [AuthGuard] },
	{ path: 'surveys', component: SurveysComponent, canActivate: [AuthGuard] },
	{ path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
	{ path: 'games/list', component: GameListComponent, canActivate: [AuthGuard] },
	{ path: 'recaps/:gameid', component: SingleRecapViewerComponent, canActivate: [AuthGuard] },
	{ path: 'recaps', component: RecapsComponent, canActivate: [AuthGuard] },
	{
		path: "reports",
		component: ReportsComponent,
		children: [
			{
				path: "content/wrong-answer/:setid",
				component: WrongAnswerComponent
			},
			{
				path: "content/wrong-answer",
				component: WrongAnswerComponent
			},
			{
				path: "content/players-by-venue",
				component: PlayersByVenueReportComponent
			}
		], canActivate: [AuthGuard]
	},
  {
    path: 'redis-keys', component: RedisKeysComponent, canActivate: [AuthGuard]
  }
	// {
	// 	path: 'historical-data', component: HistoricalDataComponent, canActivate: [AuthGuard]
	// }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
